<template>
  <Layout>
    <Interests />
  </Layout>
</template>
<script>
import Layout from "../layouts/main";
import Interests from "@/components/Interests";

export default {
  components: { Layout, Interests },
};
</script>
