var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card my-4",attrs:{"id":"interests-card"}},[_c('div',{staticClass:"card-body"},[_c('h2',{staticClass:"mb-4"},[_vm._v("Quais os seus interesses?")]),_c('div',{staticClass:"row"},_vm._l((_vm.interests),function(interest){return _c('div',{key:interest.id,staticClass:"col-xl-4 col-lg-3 col-md-6 col-12"},[_c('div',{staticClass:"card card-clickable mb-0",class:[
            {
              'active-card': _vm.selectedInterests[interest.id],
            },
          ],on:{"click":function($event){$event.preventDefault();_vm.selectedInterests[interest.id] = !_vm.selectedInterests[interest.id]}}},[_c('img',{staticClass:"card-img interest-img-fit",class:[
              {
                'interest-img-disabled': !_vm.selectedInterests[interest.id],
              },
              {
                'interest-img-enabled': _vm.selectedInterests[interest.id],
              },
            ],attrs:{"src":interest.avatar
                ? _vm.$getImageUrl(interest.avatar.originalName)
                : _vm.$defaultInterestImage,"alt":interest.name}})]),_c('h4',{staticClass:"text-center interest-text text-dark mx-1 mb-3"},[_vm._v(" "+_vm._s(interest.name)+" ")])])}),0),_c('div',{staticClass:"p-2"},[_c('button',{staticClass:"btn btn-success btn-lg mx-auto d-block my-2",on:{"click":function($event){$event.preventDefault();return _vm.update.apply(null, arguments)}}},[_c('i',{staticClass:"mdi mdi-check"}),_vm._v(" Atualizar ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }