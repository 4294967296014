<template>
  <div id="interests-card" class="card my-4">
    <div class="card-body">
      <h2 class="mb-4">Quais os seus interesses?</h2>

      <div class="row">
        <div
          v-for="interest in interests"
          :key="interest.id"
          class="col-xl-4 col-lg-3 col-md-6 col-12"
        >
          <div
            class="card card-clickable mb-0"
            @click.prevent="
              selectedInterests[interest.id] = !selectedInterests[interest.id]
            "
            :class="[
              {
                'active-card': selectedInterests[interest.id],
              },
            ]"
          >
            <img
              :src="
                interest.avatar
                  ? $getImageUrl(interest.avatar.originalName)
                  : $defaultInterestImage
              "
              class="card-img interest-img-fit"
              :class="[
                {
                  'interest-img-disabled': !selectedInterests[interest.id],
                },
                {
                  'interest-img-enabled': selectedInterests[interest.id],
                },
              ]"
              :alt="interest.name"
            />
          </div>
          <h4 class="text-center interest-text text-dark mx-1 mb-3">
            {{ interest.name }}
          </h4>
        </div>
      </div>
      <div class="p-2">
        <button
          @click.prevent="update"
          class="btn btn-success btn-lg mx-auto d-block my-2"
        >
          <i class="mdi mdi-check"></i>
          Atualizar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/api";
import { authComputed } from "@/state/helpers";

export default {
  name: "Interests",

  components: {},
  data() {
    return {
      loading: true,
      interests: [],
      selectedInterests: {},
    };
  },

  computed: {
    ...authComputed,
  },

  created() {
    api
      .getAllInterests()
      .then((interests) => {
        this.loading = false;
        this.interests = interests;
        this.selectedInterests = interests.reduce((acc, interest) => {
          acc[interest.id] = this.currentUser.interests.some(
            (i) => i.id === interest.id
          );
          return acc;
        }, {});
      })
      .catch(() => {
        this.loading = false;
        this.$swal("Opa", "Falha ao carregar interesses", "error");
      });
  },

  methods: {
    update() {
      if (!Object.keys(this.selectedInterests).length) {
        return this.$swal(
          "Opa",
          "Você precisa escolher um interesse!",
          "warning"
        );
      }

      const interests = Object.entries(this.selectedInterests)
        .filter(([, value]) => value)
        .map(([key]) => key);

      api
        .updateUser(this.currentUser.id, { interests })
        .then(() => this.$store.dispatch("auth/validate"))
        .then(() => {
          this.$swal("Sucesso", "Interesses atualizados", "success");
          if (this.$route.query.firstTime) {
            return this.$router.push({
              name: "editProfile",
              query: { firstTime: 1 },
            });
          }
          return this.$router.push({ name: "dashboard" });
        })
        .catch(() => {
          this.$swal("Opa", "Falha ao atualizar os interesses", "error");
        });
    },
  },
};
</script>

<style lang="scss">
.interest-text {
  height: 50px;
}

.interest-img-fit {
  border-radius: 0.25rem;
  height: 200px;
  object-fit: cover;
  transition: filter 1s ease-in;
}
.interest-img-disabled {
  filter: grayscale(1);
  opacity: 0.4;
  transition: ease-in 0.4s;
}

.interest-img-enabled {
  filter: grayscale(0);
  opacity: 1;
  transition: ease-out 0.4s;
}

.card-clickable {
  cursor: pointer;
}

.active-card {
  -webkit-box-shadow: 0px 0px 8px 0px rgba(18, 18, 18, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(18, 18, 18, 0.75);
  box-shadow: 0px 0px 8px 0px rgba(18, 18, 18, 0.75);
  transition: ease-in 0.4s;
  // transform: translate(-4px, -4px);
  transform: translateY(-4px);
  //transform: scale(1.02);
}
</style>
